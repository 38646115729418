import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { catchError, concat, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { AuthService } from "./auth.service";

export type CurrentContract = {
  flagExternalTicketCode: boolean;
  flagEventNotification: boolean;
  flagLeadScanner: boolean;
  flagBookMeeting: boolean;
  flagLiveStreamTrack: boolean;
  flagNetworking: boolean;
  flagBadgeCreator: boolean;
}

export interface ContractState {
  loading: boolean;
  data: CurrentContract | null;
  error: any;
}

export interface ValidateInviteResponse {
  orgName: string;
  email: string;
  sentBy: string;
}

export type OrgId = string;

export interface ApiKey {
  id: string;
  name: string;
  createdAt: string;
  key: string;
  validEndpoints?: string[];
  validEventIds?: string[];
}

export type ApiKeySecure = Omit<ApiKey, 'key'>;

export type UserInvite = {
  id: string;
  email: string;
};

@Injectable({ providedIn: "root" })
export class OrganisationService {
  contract: Observable<ContractState>;

  private contractTimestamp: number = 0;
  private contractTimeout = 30 * 60 * 1000;

  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);

  //
  // Get organisation contract
  //
  // Used everywhere to check if the organisation has access to a feature. After
  // 30 minutes it will refresh the contract on next invocation.
  //
  getContract(): Observable<ContractState> {
    const now = Date.now();

    if (!this.contract || (now - this.contractTimestamp) > this.contractTimeout) {
      this.contractTimestamp = now;

      const orgId$ = this.authService.currentOrg
        .pipe(
          map(org => org.id),
          distinctUntilChanged(),
        );

      this.contract = orgId$
        .pipe(
          switchMap((orgId) =>
            concat(
              of({ loading: true, data: null, error: null }),
              this.http.get<CurrentContract>(`/api/orgs/${orgId}/contracts/current`)
                .pipe(
                  tap(() => console.log('OrganisationService.getContract loaded')),
                  map((contract) => ({ loading: false, data: contract, error: null })),
                  catchError((error) =>
                    of({ loading: false, data: null, error })
                      .pipe(
                        // Reset cache and notify sentry of error
                        tap(() => {
                          Sentry.captureException(error);
                          this.contractTimestamp = 0;
                        })
                      )
                  ),
                )
            )
          ),
          shareReplay({ bufferSize: 1, refCount: false }),
        );
    }

    return this.contract;
  }

  //
  // Reset the contract cache
  //
  resetContractCache(): void {
    this.contractTimestamp = 0;
  }

  createOrganisation(body: { name: string; terms: boolean; }): Observable<{ orgId: string }> {
    return this.http
      .post<{ orgId: string }>('/api/orgs', { ...body, expectedNumberAttendees: 'N/A' })
      .pipe(
        switchMap((response) =>
          this.authService
            .setCurrentOrganisation(response.orgId)
            .pipe(map(() => response))
        )
      );
  }

  updateOrganisation({ id, name }: { id: string; name: string; }): Observable<void> {
    return this.http.put(`/api/orgs/${id}`, { name })
      .pipe(
        switchMap(() =>
          this.authService.setCurrentOrganisation(id)
            .pipe(map(() => void 0))
        )
      );
  }

  listOrganisations(): Observable<{ organisations: { id: string; name: string }[] }> {
    return this.http.get<{ organisations: { id: string; name: string }[] }>('/api/orgs');
  }

  validateUserInviteCode(code: string): Observable<ValidateInviteResponse> {
    const url = `/api/organisation-invites/validate`;
    return this.http.post<ValidateInviteResponse>(url, { code });
  }

  acceptUserInviteByCode(code: string): Observable<{ orgId: string }> {
    const url = `/api/organisation-invites/accept`;
    const body = { code };
    return this.http.post<{ orgId: string }>(url, body);
  }

  removeInvite(orgId: string, inviteId: string) {
    const url = `/api/orgs/${orgId}/invites/${inviteId}`;
    return this.http.delete<any>(url);
  }

  addInvite(orgId: string, userEmail: string, sentBy: string, role: string, origin: string) {
    const url = `/api/orgs/${orgId}/invites`;
    const body = { userEmail, sentBy, origin, role };
    return this.http.post<any>(url, body);
  }

  updateUser(orgId: string, userId: string, role: string) {
    const url = `/api/orgs/${orgId}/users/${userId}`
    const body = { role };
    return this.http.put<any>(url, body);
  }

  getUserInvites(orgId: string): Observable<{ invites: UserInvite[] }> {
    const url = `/api/orgs/${orgId}/invites`;
    return this.http.get<{ invites: UserInvite[] }>(url);
  }

  deleteUser(orgId: string, userId: string) {
    const url = `/api/orgs/${orgId}/users/${userId}`;
    return this.http.delete<any>(url);
  }

  //
  // API Keys
  //

  getApiKey(orgId: string, keyId: string): Observable<ApiKey> {
    const url = `/api/orgs/${orgId}/keys/${keyId}`;
    return this.http.get<ApiKey>(url);
  }

  createApiKey(orgId: string, body: { name: string }): Observable<ApiKey> {
    const url = `/api/orgs/${orgId}/keys`;
    return this.http.post<ApiKey>(url, body);
  }

  updateApiKey(orgId: string, keyId: string, body: { name: string; validEndpoints?: string[]; validEventIds?: string[] }): Observable<ApiKey> {
    const url = `/api/orgs/${orgId}/keys/${keyId}`;
    return this.http.put<ApiKey>(url, body);
  }

  listApiKeys(orgId: string): Observable<{ keys: ApiKeySecure[] }> {
    const url = `/api/orgs/${orgId}/keys`;
    return this.http.get<{ keys: ApiKeySecure[] }>(url);
  }

  deleteApiKey(orgId: string, keyId: string): Observable<{ id: string }> {
    const url = `/api/orgs/${orgId}/keys/${keyId}`;
    return this.http.delete<{ id: string }>(url, {});
  }

}
